.main{
    display:flex;
    flex-direction: column;
    background-color: slategray;
    font-family: Arial, Helvetica, sans-serif;
}

.container{
    display:flex;
    flex-direction: row;
    /* background-color: white; */
    /* border: 1px solid black; */
    /* border-radius: 30px; */
    justify-content: center;
    text-align: center;
    align-content: center;
}

.containerContent{display:flex;
    background-color: white; 
    border: 1px solid black; 
    border-radius: 30px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    min-height:50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    /* min-width: 300px; */
}

.containerContentGraph{
    background-color: white; 
    /* border: 1px solid black; 
    border-radius: 30px; */
    align-items: center;
    justify-content: center;
    /* margin: 40px;; */
    padding-top: 30px;
    padding-bottom: 40px;
}

.containerContentData{
    background-color: white; 
    border: 1px solid black; 
    border-radius: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height:50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.table{
    background-color: white;
}

.tableHeader{
    background-color: darkgray;
    border: 1px solid black;
}

.tableRow{
    background-color: white;
}

.tableData{
    background-color: white;
    border: 1px solid black;
}

